import { api, escalateError, getResponseData } from './index';

export default class DataApi {
  // Login
  static getUserEmailSession(email, token) {
    return api.get(`/users/user?email=${email}&purpose=user`,
      { headers: { Authorization: token } })
      .then(getResponseData)
      .catch(escalateError);
  }

  static postUser(data) {
    const {
      email, userInfo, id, permissionId, token, isEnterprise, password, name, lastName, ...rest
    } = data;
    const body = {
      email,
      purpose: 'user',
      userData: {
        ...rest,
        id,
        name,
        lastName,
        locale: userInfo.locale,
        hd: userInfo.hd,
        picture: userInfo.picture,
        isEnterprise,
      },
      name,
      password,
      permissionId,
    };

    return api.post('/users', body,
      { headers: { Authorization: token } })
      .then(getResponseData)
      .catch(escalateError);
  }
}
