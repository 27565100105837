import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/auth';
// eslint-disable-next-line
import firebaseConfig from './firebase-config.json';

firebase.initializeApp(firebaseConfig);

try {
  firebase.analytics();
} catch (error) {
  // eslint-disable-next-line no-console
  console.log(error);
}

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.requestPermission()
    .then(() => messaging.getToken())
    .then((token) => {
      localStorage.setItem('pushToken', token);
    })
    .catch(() => { });
}

export default firebase;
